<template>
  <page container-type="centered-sm">
    <page-row>
      <page-column>
        <div class="d-flex align-items-center">
          <img class="page__logo--large" src="../assets/images/my-port-logo.svg" alt="KING logo" />
          <h1 class="pl-3">{{ $t('view.home.login.title') }}</h1>
        </div>

        <p>{{ $t('view.home.login.description') }}</p>

        <hr />

        <p v-markdown="$t('view.home.login.comment')" />

        <iam-connected-btn href="/api/openid/connect" class="mb-3">
          {{ $t('view.home.login.button') }}
        </iam-connected-btn>
        <div class="row justify-content-end p-0">
          <img class="page__por-logo" src="../assets/por-logo.svg" alt="" />
        </div>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import iamConnectedBtn from 'poronline-shared-vue-components/components/iam-connected-btn';
import Page from '../components/page/page';

export default {
  name: 'LoginView',
  components: { Page, PageRow, PageColumn, iamConnectedBtn },
};
</script>

<style lang="scss" scoped>
@import '~poronline-shared-vue-components/styles/variables';
@import '~poronline-shared-vue-components/styles/type';

.page {
  &__logo--large {
    max-width: 60px;
    height: auto;
  }

  &__por-logo {
    height: auto;
    max-width: 100px;
    overflow: hidden;
  }

  h1 {
    @extend %h2;
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }
}
</style>
